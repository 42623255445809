<template>
  <v-dialog :value="true" max-width="700" width="100%" persistent scrollable>
    <v-card>
      <v-card-title>
        <v-stepper v-model="step" flat>
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1"
              >Datei hochladen</v-stepper-step
            >
            <v-stepper-step :complete="step > 2" step="2"
              >Überprüfen</v-stepper-step
            >
            <v-stepper-step :complete="step > 2 && !importer.running" step="3"
              >Import</v-stepper-step
            >
          </v-stepper-header>
        </v-stepper>
      </v-card-title>
      <v-card-text>
        <v-alert
          rounded
          v-if="importer.running"
          icon="mdi-sync"
          prominent
          color="warning"
          border="left"
        >
          <v-row>
            <v-col v-if="importer.startedBy">
              Aktueller Import: <DateValue :value="importer.startDate" />
              {{ importer.startTime }} ({{ importer.startedBy.name }})
            </v-col>
            <v-col>
              <v-progress-linear
                color="primary"
                :value="(100 * importer.progress) / importer.progressMax"
              ></v-progress-linear>
              {{
                Math.round((100 * importer.progress) / importer.progressMax)
              }}%
            </v-col>
          </v-row>
        </v-alert>
        <v-alert
          v-else
          prominent
          dark
          icon="mdi-history"
          border="left"
          color="success"
        >
          <v-row align="center">
            <v-col class="grow" v-if="importer.startedBy">
              Letzter Import: <DateValue :value="importer.startDate" />
              {{ importer.startTime }} ({{ importer.startedBy.name }})
            </v-col>
          </v-row>
        </v-alert>
        <v-stepper v-model="step" flat>
          <v-stepper-items>
            <v-stepper-content step="1">
              Wähle die Untis-Datei zum Hochladen aus:
              <form ref="uploadForm">
                <v-file-input
                  name="file"
                  accept=".xml"
                  label="Untis-Datei"
                ></v-file-input>
              </form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-data-table
                :headers="headers"
                :items="importer.log"
                :items-per-page="15"
              >
                <template v-slot:item.date="{ item }">
                  <DateValue :value="item.date" />, {{ item.time }}
                </template>
              </v-data-table>

              <v-divider></v-divider>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-data-table
                :headers="headers"
                :items="importer.log"
                :items-per-page="15"
              >
                <template v-slot:item.date="{ item }">
                  <DateValue :value="item.date" />, {{ item.time }}
                </template>
              </v-data-table>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-stepper v-model="step" flat>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-btn @click="upload" color="primary">Weiter</v-btn>
              <v-btn @click="close" text>Abbrechen</v-btn>
            </v-stepper-content>
            <v-stepper-content step="2">
              <TermInput v-model="term" />
              <v-btn @click="run" color="primary" :disabled="!term"
                >Weiter</v-btn
              >
              <v-btn @click="close" text>Abbrechen</v-btn>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-btn @click="stop" color="error" :disabled="!importer.running"
                >Stopp</v-btn
              >
              <v-btn @click="close" text>Schliessen</v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DateValue from 'common/components/DateValue.vue';
import TermInput from '@/components/TermInput.vue';
export default {
  name: '',
  components: { DateValue, TermInput },
  data() {
    return {
      step: 1,
      importer: { running: false, log: [], startedBy: { name: '' } },
      term: false,
      headers: [
        { text: 'Zeit', value: 'date' },
        { text: 'Level', value: 'level' },
        { text: 'Message', value: 'message' },
      ],
    };
  },
  methods: {
    close() {
      this.$router.back();
    },
    async upload() {
      var formElement = this.$refs.uploadForm;
      await this.apiUploadPost({ resource: 'change/untis', form: formElement });
      await this.getData();
      this.step = 2;
    },
    async run() {
      await this.apiPut({
        resource: 'change/untis',
        data: { term: this.term },
      });
      await this.getData();
      this.step = 3;
    },
    async stop() {
      await this.apiDelete({ resource: 'change/untis', id: 0 });
      await this.getData();
    },
    async getData() {
      this.importer = await this.apiList({ resource: 'change/untis' });
      if (this.importer.running) {
        setTimeout(this.getData, 1000);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
